import React, { useEffect } from "react";
import { useAppStore } from "../../../../store/store";
import { stringTruncate } from "../../../../helpers/utils";
import Copy from "../../../molecules/copy";
import { handleMetamask } from "../../../../helpers/wallets";
import { chains } from "../../../../helpers/config";

const env = process.env.NEXT_PUBLIC_ENVIRONMENT!;

const MetamaskConnection = () => {
  const metaMaskInfo = useAppStore((state) => state.metaMaskInfo);
  useEffect(() => {
    // listen for account changes
    if (metaMaskInfo.walletConnected) {
      let metamaskProvider: any;
      if (window.ethereum?.providers) {
        metamaskProvider = window.ethereum?.providers.find((item: any) => {
          return item && item.isMetaMask;
        });
      } else {
        metamaskProvider = window.ethereum;
      }

      metamaskProvider?.on("accountsChanged", function (accounts: any) {
        handleMetamask();
      });

      metamaskProvider?.on("chainChanged", function (chainId: string) {
        if (chainId !== chains[env].networkIdHex) {
          alert("Network changed please connect again");
          window.location.reload();
        }
      });
    }
  }, [metaMaskInfo]);

  return (
    <div>
      <div
        className={`bg-[#1B1B1B] mt-2 mb-4 flex items-center justify-center rounded-md py-3 px-8`}
      >
        <img
          src={"/images/eth_logo.svg"}
          width={26}
          height={26}
          className="mr-2"
          alt="ETH Logo"
        />
        <span className="text-light-emphasis text-lg font-medium leading-normal text-center">
          Ethereum
        </span>
      </div>
      {metaMaskInfo.walletConnected ? (
        <div className="rounded-md py-1.5 flex items-center justify-center px-4 py-1.5 w-full">
          <img
            width={16}
            height={16}
            className="mr-2 pt-0"
            src={"/images/metamask.svg"}
            alt="metamask"
          />
          <span className="text-[#47C28B] text-xs">
            <Copy
              id={metaMaskInfo.address}
              customView={
                <span className="cursor-pointer">
                  {stringTruncate(metaMaskInfo.address, 6)}
                </span>
              }
            />
          </span>
        </div>
      ) : (
        <button
          className="text-light-emphasis border-2 flex items-center border-[#E59636]
         rounded-md px-4 py-1.5 items-center justify-center hover:bg-red text-sm md:text-xsm w-full"
          onClick={() => handleMetamask()}
        >
          <img
            width={16}
            height={16}
            className="mr-2"
            src={"/images/metamask.svg"}
            alt="metamask"
          />
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default MetamaskConnection;
