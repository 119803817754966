import React, { useEffect, useState } from "react";
import Button from "../../atoms/button";
import { useAppStore } from "../../../store/store";
import {
  executeGravityToEthTransaction,
  executeGravityToPersistenceTransaction,
} from "../../../helpers/transaction";
import { Spinner } from "../../atoms/spinner";
import { getChainFee } from "../../../helpers/utils";

const Submit = () => {
  const [errorCheck, setErrorCheck] = useState<string>("");

  const flowType = useAppStore((state) => state.flowType);
  const gravityTxnFee = useAppStore((state) => state.gravityTxnFee);
  const transactionInfo = useAppStore((state) => state.transactionInfo);
  const balances = useAppStore((state) => state.balances);

  const setCosmosToEthChainFee = useAppStore(
    (state) => state.setCosmosToEthChainFee
  );

  const ibcToPersistenceHandler = async () => {
    await executeGravityToPersistenceTransaction(balances.keplr.gravityBalance);
  };

  const toEthereumHandler = async () => {
    await executeGravityToEthTransaction(balances.keplr.gravityBalance.toDec());
  };

  useEffect(() => {
    if (flowType === "normal") {
      const chainFee = getChainFee(balances.keplr.gravityBalance.toDec());
      setCosmosToEthChainFee(balances.keplr.gravityBalance.toDec());
      const fees = gravityTxnFee.selectedBridgeFee.fee
        .toDec()
        .add(chainFee.toDec());
      if (fees.lte(balances.keplr.gravityBalance.toDec())) {
        setErrorCheck("");
      } else {
        setErrorCheck("Balance not enough to cover the bridge fee");
      }
    }
  }, [
    flowType,
    gravityTxnFee.selectedBridgeFee,
    balances.keplr.gravityBalance,
  ]);

  return (
    <div className="">
      {errorCheck !== "" ? (
        <p className="text-red font-normal leading-normal text-xsm pt-2">
          {errorCheck}
        </p>
      ) : (
        <p className="text-red font-normal leading-normal text-xsm invisible pt-2">
          test
        </p>
      )}
      <div className="mt-6 flex items-center justify-center">
        <Button
          className={`button w-[250px] md:py-2 md:text-sm flex items-center justify-center`}
          type="primary"
          size="large"
          disabled={
            errorCheck !== "" ||
            ((transactionInfo.name === "gravityToEthereum" ||
              transactionInfo.name === "gravityToPersistence") &&
              transactionInfo.inProgress)
          }
          content={
            (transactionInfo.name === "gravityToEthereum" ||
              transactionInfo.name === "gravityToPersistence") &&
            transactionInfo.inProgress ? (
              <Spinner size={"small"} />
            ) : (
              "Transfer"
            )
          }
          onClick={
            flowType === "reverse" ? ibcToPersistenceHandler : toEthereumHandler
          }
        />
      </div>
    </div>
  );
};

export default Submit;
