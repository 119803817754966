import React, { useState } from "react";
import { useAppStore } from "../../../store/store";
import Modal from "../../molecules/modal";
import Radio from "../../atoms/radio";
import Submit from "./submit";
import { GravityTxnOption } from "../../../store/slices/transactions";
import BridgeFee from "../homepage/bridgeFee";

const IbcTransferModal = () => {
  const setGravityTxnModal = useAppStore((state) => state.setGravityTxnModal);
  const [check, setCheck] = useState<boolean>(true);
  const gravityTxnType = useAppStore(
    (state) => state.gravityTxn.gravityTxnType
  );
  const gravityTxnFee = useAppStore((state) => state.gravityTxnFee);
  const flowType = useAppStore((state) => state.flowType);
  const setGravityTxnType = useAppStore((state) => state.setGravityTxnType);
  const modal = useAppStore((state) => state.gravityTxn.modal);
  const balances = useAppStore((state) => state.balances);

  const handleClose = () => {
    setGravityTxnModal(false);
  };

  const handleRadioChange = (value: GravityTxnOption) => {
    if (value !== gravityTxnType) {
      setCheck(!check);
      setGravityTxnType(value);
    }
  };

  return (
    <Modal
      show={modal}
      onClose={handleClose}
      header=""
      className="transfer-modal"
      staticBackDrop={true}
      closeButton={true}
    >
      <p
        className="text-light-high text-center font-semibold text-lg leading normal px-8 pt-8 md:px-6 md:pt-6
       md:text-base"
      >
        Transfer PSTAKE to{" "}
        <span className="capitalize">
          {flowType === "normal" ? "Ethereum" : "Persistence"}
        </span>
      </p>
      <div className={`px-8 pt-4 pb-8 md:px-7 md:pt-6 m-0`}>
        {flowType === "normal" ? (
          <p className="mb-4">
            You have{" "}
            <b>
              {balances.keplr.gravityBalance
                .toDec()
                .sub(gravityTxnFee.selectedBridgeFee.fee.toDec())
                .isNegative()
                ? balances.keplr.gravityBalance.toString()
                : balances.keplr.gravityBalance
                    .toDec()
                    .sub(gravityTxnFee.selectedBridgeFee.fee.toDec())
                    .toString()}{" "}
              PSTAKE
            </b>{" "}
            in your Gravity Bridge Wallet. Click on Transfer to send your tokens
            from Gravity Bridge chain to Ethereum chain
          </p>
        ) : (
          <p className="mb-4">
            You have{" "}
            <b>{Number(balances.keplr.gravityBalance).toFixed(6)} PSTAKE</b> in
            your Gravity Bridge Wallet. Click on Transfer to send your tokens
            from Gravity Bridge chain to Persistence chain
          </p>
        )}
        <div className="flex">
          <Radio
            checked={check}
            label="Persistence"
            id={"Persistence"}
            className="mr-3"
            onClick={() => handleRadioChange("persistence")}
          />
          <Radio
            checked={!check}
            label="Ethereum"
            id={"Ethereum"}
            onClick={() => handleRadioChange("ethereum")}
          />
        </div>
        {flowType === "normal" ? (
          <div>
            <p className={"text-light-emphasis font-medium mb-2 ml-2"}>
              Bridge Fee
            </p>
            <BridgeFee />
          </div>
        ) : (
          ""
        )}
        <Submit />
      </div>
    </Modal>
  );
};

export default IbcTransferModal;
