import React from "react";
import { displayToast } from "../components/molecules/toast";
import { ToastType } from "../components/molecules/toast/types";
import { BigNumberish, ContractTransaction, ethers } from "ethers";
import {
  exceptionHandle,
  gravityChain,
  keplrWallet,
  MakeIBCTransferMsg,
  persistenceChain,
  pollAccountBalance, removeCommas,
  stringTruncate,
} from "./utils";
import { Icon } from "../components/atoms/icon";
import { useAppStore } from "../store/store";
import { Gravity, Pstake } from "../contracts/types";
import { chains, contracts, ibcConfiguration, pstakeInfo } from "./config";
import { OfflineSigner } from "@cosmjs/launchpad";
import {
  AminoConverters,
  AminoTypes,
  defaultRegistryTypes,
  DeliverTxResponse,
  GasPrice,
  SigningStargateClient,
} from "@cosmjs/stargate";
import {
  GeneratedType,
  OfflineDirectSigner,
  Registry,
} from "@cosmjs/proto-signing";
import {
  AMOUNT_SAFE,
  GRAVITY_FEE,
  GRAVITY_SEND_URL,
  PERSISTENCE_FEE,
} from "../../appConstants";
import { SendMsg } from "./protoMsg";
import { MsgSendToEth } from "./proto/gravity/v1/gravity/v1/msgs";
import { CoinPretty, Dec } from "@keplr-wallet/unit";
import { getUnDecimalizedValue } from "./coin";

const env: string = process.env.NEXT_PUBLIC_ENVIRONMENT!;

export type transferParams = {
  persistenceAddress: string;
  amount: BigNumberish;
};

export type CosmosToEthTxnParams = {
  persistenceAddress: string;
  gravityAddress: string;
  amount: string;
};

import { createGravityAminoConverters } from "./aminoConvter";
import {
  createAuthzAminoConverters,
  createBankAminoConverters,
  createDistributionAminoConverters,
  createFeegrantAminoConverters,
  createGovAminoConverters,
  createIbcAminoConverters,
  createStakingAminoConverters,
  createVestingAminoConverters,
} from "@cosmjs/stargate";

function createAminoTypes(): AminoConverters {
  return {
    ...createAuthzAminoConverters(),
    ...createBankAminoConverters(),
    ...createDistributionAminoConverters(),
    ...createGovAminoConverters(),
    ...createStakingAminoConverters(),
    ...createIbcAminoConverters(),
    ...createFeegrantAminoConverters(),
    ...createVestingAminoConverters(),
    ...createGravityAminoConverters(),
  };
}

export const registryGravity: ReadonlyArray<[string, GeneratedType]> = [
  [GRAVITY_SEND_URL, MsgSendToEth],
];

export const protoRegistry: ReadonlyArray<[string, GeneratedType]> = [
  ...registryGravity,
];

export async function Transaction(
  signer: OfflineSigner | OfflineDirectSigner,
  signerAddress: string,
  msgs: any,
  gasPrice: string,
  memo = "",
  rpc: string
) {
  const client = await SigningStargateClient.connectWithSigner(rpc, signer, {
    registry: new Registry([...defaultRegistryTypes, ...protoRegistry]),
    gasPrice: GasPrice.fromString(gasPrice),
    aminoTypes: new AminoTypes(createAminoTypes()),
  });
  return await client.signAndBroadcast(signerAddress, msgs, "auto", memo);
}

export const formBlockExplorerLink = (txnHash: string) => {
  const chain = chains[env].explorerUrl;
  if (txnHash) return `${chain}/tx/${txnHash}`;
  return "";
};

export const failedTransactionActions = (txnHash: string) => {
  displayToast(
    {
      message: `This transaction could not be completed${
        txnHash ? `: ${txnHash}` : ""
      }`,
    },
    ToastType.ERROR
  );
};

export const executeEthApproveTransaction = async (instance: Pstake) => {
  try {
    useAppStore.getState().setTxnInfo(true, "approve");
    const mxUint256 = ethers.constants.MaxUint256;
    const gravityContractAddress =
      contracts[process.env.NEXT_PUBLIC_ENVIRONMENT!]["gravity"];
    const txn: ContractTransaction | undefined = await instance?.approve(
      gravityContractAddress,
      mxUint256
    );
    await transactionActions(txn);
  } catch (e: any) {
    exceptionHandle(e, { "Error while un-staking": "" });
  }
};

export const executeEthToCosmosTransaction = async (
  instance: Gravity | undefined,
  contractParams: transferParams
) => {
  try {
    useAppStore.getState().setEthToCosmosTxnModal(true);
    useAppStore.getState().setTxnInfo(true, "ethTransfer");
    const pstakeContractAddress =
      contracts[process.env.NEXT_PUBLIC_ENVIRONMENT!]["pstake"];
    const txn: ContractTransaction | undefined = await instance?.sendToCosmos(
      pstakeContractAddress,
      contractParams.persistenceAddress,
      contractParams.amount
    );
    await transactionActions(txn);
  } catch (e: any) {
    exceptionHandle(e, { "Error while un-staking": "" });
  }
};

export type sparams = {
  token: number;
};

export const executeCosmosToEthTransaction = async (transferAmount: Dec) => {
  try {
    useAppStore.getState().setTxnInfo(true, "cosmosTransfer");
    useAppStore.getState().setCosmosToEthTxnModal(true);
    useAppStore.getState().setCosmosToEthTxnStep(1);
    const keplrInfo = useAppStore.getState().keplrInfo;
    const metaMaskAddress = useAppStore.getState().metaMaskInfo.address;
    const initialBalance = useAppStore.getState().balances.keplr.pBalance;
    const gravityTxnFee = useAppStore.getState().gravityTxnFee;
    const totalAmount = transferAmount.add(
      gravityTxnFee.selectedBridgeFee.fee.toDec()
    );
    console.log(totalAmount, "totalAmount");
    let msg = await MakeIBCTransferMsg({
      channel: ibcConfiguration?.ibcGravityChannelID,
      fromAddress: keplrInfo.persistenceAddress!,
      toAddress: keplrInfo.gravityAddress,
      amount: getUnDecimalizedValue(totalAmount.toString(), 18)
        .truncate()
        .toString(),
      timeoutHeight: undefined,
      timeoutTimestamp: undefined,
      denom: pstakeInfo.coinMinimalDenom,
      sourceRPCUrl: persistenceChain?.rpc,
      destinationRPCUrl: gravityChain?.rpc,
      port: ibcConfiguration.ibcDefaultPort,
    });
    console.log(msg, "msg");
    const persistenceSigner = await keplrWallet("core-1");
    const persistenceTransaction: DeliverTxResponse = await Transaction(
      persistenceSigner.signer!,
      keplrInfo.persistenceAddress,
      [msg],
      PERSISTENCE_FEE,
      "",
      persistenceChain!.rpc
    );
    console.log(persistenceTransaction, "persistenceTransaction");
    if (persistenceTransaction.code === 0) {
      const response: CoinPretty = await pollAccountBalance(
        keplrInfo.persistenceAddress,
        pstakeInfo.coinMinimalDenom,
        persistenceChain,
        initialBalance
      );

      const amountToTransfer = transferAmount.sub(
        gravityTxnFee.chainFee.toDec()
      );

      console.log(response, "response");
      console.log(persistenceTransaction, "persistenceTransaction");
      if (!response.toDec().equals(new Dec("0"))) {
        useAppStore.getState().setCosmosToEthTxnStep(2);
        const sendMsg = SendMsg(
          keplrInfo.gravityAddress,
          metaMaskAddress,
          getUnDecimalizedValue(amountToTransfer.toString(), 18)
            .truncate()
            .toString(),
          pstakeInfo.baseDenom,
          getUnDecimalizedValue(
            gravityTxnFee.selectedBridgeFee.fee.toString(),
            18
          )
            .truncate()
            .toString(),
          getUnDecimalizedValue(gravityTxnFee.chainFee.toString(), 18)
            .truncate()
            .toString()
        );
        const gravitySigner: any = await keplrWallet(gravityChain!.chainId!);
        console.log(sendMsg, "sendMsg", totalAmount);
        const gravityTransaction: DeliverTxResponse = await Transaction(
          gravitySigner.signer!,
          keplrInfo.gravityAddress,
          [sendMsg],
          GRAVITY_FEE,
          "",
          gravityChain!.rpc
        );
        console.log(gravityTransaction, "gravityTransaction");
        if (gravityTransaction.code === 0) {
          await useAppStore
            .getState()
            .fetchKeplrBalances(
              keplrInfo.persistenceAddress,
              keplrInfo.gravityAddress
            );
          useAppStore.getState().setCosmosToEthTxnStep(-1);
          useAppStore.getState().setCosmosToEthTxnModal(false);
          useAppStore.getState().setTxnInfo(false, null);
          useAppStore.getState().setCosmosToEthChainFee(new Dec("0"));
          displayToast(
            {
              message: "Tokens successfully sent to relayer",
            },
            ToastType.SUCCESS
          );
        } else {
          throw new Error("transferring to relayer failed");
        }
      } else {
        throw new Error("polling failed");
      }
    } else {
      throw new Error("Error while transferring");
    }
  } catch (e: any) {
    useAppStore.getState().setCosmosToEthChainFee(new Dec("0"));
    await useAppStore
      .getState()
      .fetchKeplrBalances(
        useAppStore.getState().keplrInfo.persistenceAddress,
        useAppStore.getState().keplrInfo.gravityAddress
      );
    useAppStore.getState().setCosmosToEthTxnStep(0);
    exceptionHandle(e, { "Error while transferring": "" });
  }
};

export const executeGravityToEthTransaction = async (transferAmount: Dec) => {
  try {
    useAppStore.getState().setTxnInfo(true, "gravityToEthereum");
    const keplrInfo = useAppStore.getState().keplrInfo;
    const metaMaskAddress = useAppStore.getState().metaMaskInfo.address;
    const gravityTxnFee = useAppStore.getState().gravityTxnFee;

    const amount = transferAmount
      .sub(gravityTxnFee.chainFee.toDec())
      .sub(gravityTxnFee.selectedBridgeFee.fee.toDec());

    const gravitySigner: any = await keplrWallet(gravityChain!.chainId!);
    const sendMsg = SendMsg(
      keplrInfo.gravityAddress,
      metaMaskAddress,
      getUnDecimalizedValue(amount.toString(), 18).truncate().toString(),
      pstakeInfo.baseDenom,
      getUnDecimalizedValue(gravityTxnFee.selectedBridgeFee.fee.toString(), 18)
        .truncate()
        .toString(),
      getUnDecimalizedValue(gravityTxnFee.chainFee.toString(), 18)
        .truncate()
        .toString()
    );

    const gravityTransaction: DeliverTxResponse = await Transaction(
      gravitySigner.signer!,
      keplrInfo.gravityAddress,
      [sendMsg],
      GRAVITY_FEE,
      "",
      gravityChain!.rpc
    );
    console.log(gravityTransaction, "gravityTransaction");
    if (gravityTransaction.code === 0) {
      await useAppStore
        .getState()
        .fetchKeplrBalances(
          keplrInfo.persistenceAddress,
          keplrInfo.gravityAddress
        );
      useAppStore.getState().setGravityTxnModal(false);
      useAppStore.getState().setTxnInfo(false, null);
      displayToast(
        {
          message: "Tokens successfully sent to relayer",
        },
        ToastType.SUCCESS
      );
    } else {
      throw new Error("transferring to relayer failed");
    }
  } catch (e: any) {
    await useAppStore
      .getState()
      .fetchKeplrBalances(
        useAppStore.getState().keplrInfo.persistenceAddress,
        useAppStore.getState().keplrInfo.gravityAddress
      );
    useAppStore.getState().setGravityTxnModal(false);
    exceptionHandle(e, { "Error while transferring": "" });
  }
};

export const executeGravityToPersistenceTransaction = async (
  transferAmount: CoinPretty
) => {
  try {
    useAppStore.getState().setTxnInfo(true, "gravityToPersistence");
    const keplrInfo = useAppStore.getState().keplrInfo;
    let msg = await MakeIBCTransferMsg({
      channel: ibcConfiguration?.ibcChannelID,
      fromAddress: keplrInfo.gravityAddress!,
      toAddress: keplrInfo.persistenceAddress,
      amount: getUnDecimalizedValue(removeCommas(transferAmount.toString()), 18)
        .truncate()
        .toString(),
      timeoutHeight: undefined,
      timeoutTimestamp: undefined,
      denom: pstakeInfo.baseDenom,
      sourceRPCUrl: gravityChain?.rpc,
      destinationRPCUrl: persistenceChain?.rpc,
      port: ibcConfiguration.ibcDefaultPort,
    });
    const gravitySigner: any = await keplrWallet(gravityChain!.chainId!);
    const persistenceTransaction: DeliverTxResponse = await Transaction(
      gravitySigner.signer,
      keplrInfo.gravityAddress,
      [msg],
      GRAVITY_FEE,
      "",
      gravityChain!.rpc
    );
    console.log(persistenceTransaction, "persistenceTransaction");
    if (persistenceTransaction.code === 0) {
      const response: CoinPretty = await pollAccountBalance(
        keplrInfo.gravityAddress,
        pstakeInfo.baseDenom,
        gravityChain,
        transferAmount
      );
      console.log(response, "response");
      if (!response.toDec().equals(new Dec("0"))) {
        await useAppStore
          .getState()
          .fetchKeplrBalances(
            useAppStore.getState().keplrInfo.persistenceAddress,
            useAppStore.getState().keplrInfo.gravityAddress
          );
        useAppStore.getState().setGravityTxnModal(false);
        useAppStore.getState().setTxnInfo(false, null);
        displayToast(
          {
            message: "Tokens sent to persistence",
          },
          ToastType.SUCCESS
        );
      } else {
        throw new Error("Error while transferring");
      }
    } else {
      throw new Error("Error while transferring");
    }
  } catch (e: any) {
    await useAppStore
      .getState()
      .fetchKeplrBalances(
        useAppStore.getState().keplrInfo.persistenceAddress,
        useAppStore.getState().keplrInfo.gravityAddress
      );
    useAppStore.getState().setGravityTxnModal(false);
    exceptionHandle(e, { "Error while transferring": "" });
  }
};

// common actions for all transactions
export const transactionActions = async (
  txn: ContractTransaction | undefined,
  account?: string
) => {
  displayToast(
    {
      message: "Waiting for confirmation",
    },
    ToastType.LOADING
  );
  try {
    await txn!.wait(3);
    displayToast(
      {
        message: (
          <>
            <a
              rel="noreferrer"
              className="flex items-center"
              target={"_blank"}
              href={formBlockExplorerLink(txn!.hash)}
            >
              {stringTruncate(txn!.hash)}
              <Icon iconName="arrow-redirect" viewClass="icon" />
            </a>
          </>
        ),
      },
      ToastType.SUCCESS
    );
    const metaMaskInfo = useAppStore.getState().metaMaskInfo;
    const transactionInfo = useAppStore.getState().transactionInfo;
    if (transactionInfo.name === "approve") {
      await useAppStore
        .getState()
        .fetchAllowances(metaMaskInfo.instances!, metaMaskInfo.address);
    }
    await useAppStore
      .getState()
      .fetchMetaMaskBalances(metaMaskInfo.instances!, metaMaskInfo.address);
    useAppStore.getState().setTxnInfo(false, null);
    console.log(txn);
  } catch (e) {
    exceptionHandle(e, { "Error while making txn": "" });
  }
};
