import { AminoMsg, Coin } from "@cosmjs/amino";
import { AminoConverters } from "@cosmjs/stargate";
import { MsgSendToEth } from "./proto/gravity/v1/gravity/v1/msgs";

export interface AminoMsgSendToEth extends AminoMsg {
  readonly type: "gravity/MsgSendToEth";
  readonly value: {
    readonly sender: string;
    readonly eth_dest: string;
    readonly amount?: Coin;
    readonly bridge_fee?: Coin;
    readonly chain_fee?: Coin;
  };
}

export function createGravityAminoConverters(): AminoConverters {
  return {
    "/gravity.v1.MsgSendToEth": {
      aminoType: "gravity/MsgSendToEth",
      toAmino: ({
        sender,
        ethDest,
        amount,
        bridgeFee,
        chainFee,
      }: MsgSendToEth): AminoMsgSendToEth["value"] => ({
        sender: sender,
        eth_dest: ethDest,
        amount: amount,
        bridge_fee: bridgeFee,
        chain_fee: chainFee,
      }),
      fromAmino: ({
        sender,
        eth_dest,
        amount,
        bridge_fee,
        chain_fee,
      }: AminoMsgSendToEth["value"]): MsgSendToEth => ({
        sender: sender,
        ethDest: eth_dest,
        amount: amount,
        bridgeFee: bridge_fee,
        chainFee: chain_fee,
      }),
    },
  };
}
