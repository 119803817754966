import React from "react";
import { useAppStore } from "../../../../store/store";
import { Icon } from "../../../atoms/icon";
import KeplrConnection from "./keplr";
import MetamaskConnection from "./metaMask";

const WalletConnection = () => {
  const flowType = useAppStore((state) => state.flowType);
  const updateFlowType = useAppStore((state) => state.updateFlowType);

  return (
    <div className="flex justify-between">
      <div className="w-[200px]">
        <p className="text-light-mid text-sm leading-normal">From</p>
        {flowType === "normal" ? <MetamaskConnection /> : <KeplrConnection />}
      </div>

      <div className={"flex mx-2 items-baseline pt-[30px]"}>
        <div
          className={`cursor-pointer icon-box rounded-full flex justify-center items-center border-2 border-solid border-[#272727]
          w-[48px] h-[48px] bg-[#1A1A1A] md:w-[28px] md:h-[28px] group`}
          onClick={updateFlowType}
        >
          <Icon
            iconName="arrow-two-way"
            viewClass="!w-[16px] md:!w-[12px] group-hover:fill-[#fcfcfc]"
          />
        </div>
      </div>
      <div className="w-[200px]">
        <p className="text-light-mid text-sm leading-normal">To</p>
        {flowType === "normal" ? <KeplrConnection /> : <MetamaskConnection />}
      </div>
    </div>
  );
};

export default WalletConnection;
