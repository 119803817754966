import React, { useEffect } from "react";
import { useAppStore } from "../../../../store/store";
import { displayToast } from "../../../molecules/toast";
import { ToastType } from "../../../molecules/toast/types";
import {
  exceptionHandle,
  gravityChain,
  keplrWallet,
  stringTruncate,
} from "../../../../helpers/utils";
import Copy from "../../../molecules/copy";

const KeplrConnection = () => {
  const keplrInfo = useAppStore((state) => state.keplrInfo);
  const connectKeplr = useAppStore((state) => state.connectKeplr);

  useEffect(() => {
    if (keplrInfo.persistenceAddress !== "") {
      window.addEventListener("keplr_keystorechange", async () => {
        await keplrConnect();
      });
    }
  }, [keplrInfo]);

  const keplrConnect = async () => {
    try {
      const persistenceResponse: any = await keplrWallet("core-1");
      const gravityResponse: any = await keplrWallet(gravityChain!.chainId!);
      await connectKeplr({
        persistenceAddress: persistenceResponse.address,
        persistenceSigner: persistenceResponse.signer,
        gravitySigner: gravityResponse.signer,
        gravityAddress: gravityResponse.address,
        walletConnected: true,
      });
    } catch (e: any) {
      exceptionHandle(e, { "Error while Connecting to keplr": "" });
      displayToast(
        {
          message: "Error while Connecting to keplr",
        },
        ToastType.ERROR
      );
    }
  };

  return (
    <div>
      <div
        className={`bg-[#1B1B1B] mb-4 mt-2 flex items-center justify-center rounded-md py-3 px-8 chain-box `}
      >
        <img
          src={"/images/xprt.svg"}
          width={28}
          height={28}
          className="mr-2"
          alt="atom Logo"
        />
        <span className="text-light-emphasis text-lg font-medium leading-normal text-center">
          Persistence
        </span>
      </div>
      {keplrInfo.persistenceAddress !== "" ? (
        <div className="rounded-md px-4 py-1.5 flex items-center justify-center w-full">
          <img
            width={16}
            height={16}
            className="mr-2"
            src={"/images/keplr.svg"}
            alt="keplr"
          />
          <div className="text-[#47C28B] text-xs">
            <Copy
              id={keplrInfo.persistenceAddress}
              customView={
                <span className="cursor-pointer">
                  {stringTruncate(keplrInfo.persistenceAddress, 6)}
                </span>
              }
            />
          </div>
        </div>
      ) : (
        <button
          className="text-light-emphasis border-2 flex border-[#E59636]
    rounded-md px-4 py-1.5 items-center justify-center hover:bg-red text-sm md:text-xsm  w-full"
          onClick={keplrConnect}
        >
          <img
            width={16}
            height={16}
            className="mr-2"
            src={"/images/keplr.svg"}
            alt="metamask"
          />
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default KeplrConnection;
