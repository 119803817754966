import React from "react";
import Tooltip from "rc-tooltip";
import { Icon } from "../../atoms/icon";

const Footer = () => {
  const socialList = [
    {
      url: "https://twitter.com/PersistenceOne",
      iconName: "twitter-logo",
      tooltip: "Twitter",
    },
    {
      url: "https://t.me/PersistenceOne",
      iconName: "telegram-plane",
      tooltip: "Telegram",
    },
    {
      url: "https://blog.persistence.one/",
      iconName: "medium-m",
      tooltip: "Medium",
    },
  ];

  return (
    <div className="flex items-center justify-center py-4 px-8">
      <div className={"flex items-center justify-center flex-1"}>
        <div className={`socialLinks flex`}>
          {socialList.map((item, index) => (
            <Tooltip placement="bottom" overlay={item.tooltip} key={index}>
              <a
                href={item.url}
                rel="noopener noreferrer"
                className="mr-2.5"
                target="_blank"
              >
                <Icon
                  viewClass="socialIcon fill-[#a6a6a6] !w-[14px] !h-[14px]"
                  iconName={item.iconName}
                />
              </a>
            </Tooltip>
          ))}
        </div>
        <p className="text-[#676767] text-sm font-medium">
          Powered by Gravity Bridge
        </p>
        <div className="text-light-low text-xsm font-medium leading-4 ml-2 flex items-center">
          <div className={"rounded-full w-[3px] h-[3px] bg-[#787878] mr-1"} />
          <a
              href="https://persistence.one/termsofuse"
              target="_blank"
              rel="noreferrer"
              className={"hover:underline"}
          >
            Terms of Use
          </a>
          <div
              className={"rounded-full w-[3px] h-[3px] bg-[#787878] ml-2 mr-1"}
          />
          <a
              href="https://persistence.one/privacy"
              target="_blank"
              rel="noreferrer"
              className={"hover:underline"}
          >
            Privacy Policy
          </a>
        </div>
      </div>
      <p className="text-[#676767] text-sm font-medium mr-4 hidden">
        Persistence
      </p>
    </div>
  );
};

export default Footer;
