import React, { useEffect } from "react";
import { useAppStore } from "../../../store/store";
import { BridgeFeeType } from "../../../store/slices/transactions";
import { truncateToFixedDecimalPlaces } from "../../../helpers/utils";
import Tooltip from "rc-tooltip";
import { Icon } from "../../atoms/icon";
import { CoinPretty, Dec } from "@keplr-wallet/unit";

const BridgeFee = () => {
  const gravityTxnFee = useAppStore((state) => state.gravityTxnFee);
  const tokenPrices = useAppStore((state) => state.tokenPrices);

  const setBridgeFeeType = useAppStore((state) => state.setBridgeFeeType);

  useEffect(() => {
    setBridgeFeeType({
      type: "standard",
      fee: gravityTxnFee.bridgeFee.quo(new Dec("2")),
    });
  }, [gravityTxnFee.bridgeFee]);

  const handleBridgeFee = (value: BridgeFeeType, fee: CoinPretty) => {
    setBridgeFeeType({
      type: value,
      fee,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div
        className={`flex-1 p-4 bg-black-600 items-center rounded-md mr-2 cursor-pointer 
        ${
          gravityTxnFee.selectedBridgeFee.type === "standard"
            ? "!bg-[#373737]"
            : ""
        }`}
        onClick={() =>
          handleBridgeFee("standard", gravityTxnFee.bridgeFee.quo(new Dec(2)))
        }
      >
        <p className="text-base text-light-emphasis mb-1 flex items-center">
          Standard
          <Tooltip
            placement="bottom"
            overlay={
              <span>
                Your transaction will be batched <br />
                with others in order to save on fees
              </span>
            }
          >
            <button className="icon-button px-1">
              <Icon viewClass="arrow-right" iconName="info" />
            </button>
          </Tooltip>
        </p>
        <p className="text-sm text-light-low mb-1">Batched (~24 hours)</p>
        <p className="text-sm text-light-mid">
          {truncateToFixedDecimalPlaces(
            gravityTxnFee.bridgeFee.quo(new Dec("2")).toString(),
            3
          )}{" "}
          PSTAKE ($
          {truncateToFixedDecimalPlaces(
            gravityTxnFee.bridgeFee
              .quo(new Dec("2"))
              .mul(new Dec(tokenPrices.pstakePrice))
              .toString(),
            2
          )}
          )
        </p>
      </div>
      <div
        className={`flex-1 p-4 bg-black-600 items-center rounded-md ml-2 cursor-pointer 
        ${
          gravityTxnFee.selectedBridgeFee.type === "premium"
            ? "!bg-[#373737]"
            : ""
        }`}
        onClick={() => handleBridgeFee("premium", gravityTxnFee.bridgeFee)}
      >
        <p className="text-base text-light-emphasis mb-1">Premium</p>
        <p className="text-sm text-light-low mb-1">Individual (~5 minutes)</p>
        <p className="text-sm text-light-mid">
          {truncateToFixedDecimalPlaces(gravityTxnFee.bridgeFee.toString(), 6)}{" "}
          PSTAKE ($
          {truncateToFixedDecimalPlaces(
            gravityTxnFee.bridgeFee
              .toDec()
              .mul(new Dec(tokenPrices.pstakePrice.toString()))
              .toString(),
            2
          )}
          )
        </p>
      </div>
    </div>
  );
};

export default BridgeFee;
