import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import InputText from "../../../atoms/input";
import { useWindowSize } from "../../../../customHooks/useWindowSize";
import {
  formatNumber,
  truncateToFixedDecimalPlaces,
} from "../../../../helpers/utils";
import { useAppStore } from "../../../../store/store";
import { INPUT_DECIMALS } from "../../../../../appConstants";
import { Dec } from "@keplr-wallet/unit";
import Tooltip from "rc-tooltip";
import { Icon } from "../../../atoms/icon";

const Token = () => {
  const flowType = useAppStore((state) => state.flowType);
  const metaMaskInfo = useAppStore((state) => state.metaMaskInfo);
  const tokenPrices = useAppStore((state) => state.tokenPrices);
  const keplrInfo = useAppStore((state) => state.keplrInfo);
  const balances = useAppStore((state) => state.balances);
  const selectedToken = useAppStore((state) => state.selectedToken);
  const gravityTxnFee = useAppStore((state) => state.gravityTxnFee);
  const [inputAmount, setInputAmount] = useState<string>("");

  const setSelectedTokenAmount = useAppStore(
    (state) => state.setSelectedTokenAmount
  );

  const setCosmosToEthChainFee = useAppStore(
    (state) => state.setCosmosToEthChainFee
  );

  const setSelectedTokenAmountCheck = useAppStore(
    (state) => state.setSelectedTokenAmountCheck
  );

  const [errorCheck, setErrorCheck] = useState<string>("");

  useEffect(() => {
    setErrorCheck("");
    setInputAmount("");
    setSelectedTokenAmount(new Dec("0"));
  }, [flowType]);

  useEffect(() => {
    if (keplrInfo.walletConnected && flowType === "reverse") {
      const totalBalance = balances.keplr.pBalance;
      let transferableBalance: Dec;
      if (
        totalBalance.toDec().gt(gravityTxnFee.selectedBridgeFee.fee.toDec())
      ) {
        transferableBalance = totalBalance
          .toDec()
          .sub(gravityTxnFee.selectedBridgeFee.fee.toDec());
      } else {
        transferableBalance = new Dec("0");
      }
      setCosmosToEthChainFee(
        inputAmount === ""
          ? transferableBalance
          : new Dec(inputAmount === "" ? "0" : inputAmount)
      );
      setSelectedTokenAmount(new Dec(inputAmount === "" ? "0" : inputAmount));
      if (
        new Dec(inputAmount === "" ? "0" : inputAmount).lte(transferableBalance)
      ) {
        setErrorCheck("");
        setSelectedTokenAmountCheck(false);
      } else {
        setSelectedTokenAmountCheck(true);
        setErrorCheck("Balance not enough to cover the bridge fee");
      }
    } else if (keplrInfo.walletConnected && flowType === "normal") {
      setSelectedTokenAmount(new Dec(inputAmount === "" ? "0" : inputAmount));
    }
  }, [
    balances.keplr.pBalance,
    keplrInfo.walletConnected,
    gravityTxnFee.selectedBridgeFee,
    inputAmount,
  ]);

  const { isMobile } = useWindowSize();
  const inputHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    let rex = /^\d{0,10}(\.\d{0,18})?$/;
    if (
      rex.test(evt.target.value) &&
      metaMaskInfo.walletConnected &&
      keplrInfo.walletConnected
    ) {
      setInputAmount(evt.target.value);
    } else {
      return false;
    }
  };

  const maxHandler = async (value: string) => {
    setInputAmount(value.toString());
  };

  return (
    <div>
      <div className="mb-2 flex justify-between items-center">
        <p className={"text-light-emphasis font-medium"}>Token</p>
        {flowType === "normal" ? (
          <p className={"text-light-mid font-normal"}>
            {" "}
            Est. time taken: ~15 Mins
          </p>
        ) : null}
        {flowType === "reverse" ? (
          <div
            className={
              "text-light-emphasis flex items-center font-normal text-sm cursor-pointer"
            }
          >
            {" "}
            Transferable :&nbsp;
            {balances.keplr.pBalance
              .toDec()
              .gt(gravityTxnFee.selectedBridgeFee.fee.toDec()) ? (
              <p
                onClick={() =>
                  maxHandler(
                    balances.keplr.pBalance
                      .toDec()
                      .sub(gravityTxnFee.selectedBridgeFee.fee.toDec())
                      .toString()
                  )
                }
                className="flex hover:cursor-pointer hover:underline hover:text-light-high"
              >
                {truncateToFixedDecimalPlaces(
                  balances.keplr.pBalance
                    .toDec()
                    .sub(gravityTxnFee.selectedBridgeFee.fee.toDec())
                    .toString(),
                  6
                )}{" "}
                <Tooltip
                  placement="bottom"
                  overlay={
                    <span>
                      Transferable PSTAKE <br />
                      after deducting bridge fee
                    </span>
                  }
                >
                  <button className="icon-button px-1">
                    <Icon viewClass="arrow-right" iconName="info" />
                  </button>
                </Tooltip>
              </p>
            ) : (
              <p onClick={() => maxHandler(balances.keplr.pBalance.toString())}>
                {balances.keplr.pBalance.toString()}
              </p>
            )}
          </div>
        ) : null}
      </div>
      <div className="relative inline-block w-full">
        <div className="inputContainer py-5 px-6 bg-input border rounded-md border-solid border-[#1b1b1b99] flex-wrap flex md:p-3">
          <div className="flex justify-center flex-col flex-1">
            <div className="input-logo flex items-center">
              <img
                src={"/images/logos/pstake.svg"}
                width={isMobile ? 20 : 28}
                height={isMobile ? 20 : 28}
                className="tokenImage"
                alt="ethIcon"
              />
              <span className="text-light-high text-2xl font-normal ml-2 md:text-lg md:text-lg lg:text-lg">
                PSTAKE
              </span>
            </div>
            <p className="mt-3 leading-normal text-sm md:text-xsm">
              <span className="text-light-low">Total Available: </span>
              {flowType === "reverse" ? (
                <span className="text-light-mid">
                  {balances.keplr.pBalance.toString()}
                </span>
              ) : (
                <span className="text-light-mid">
                  {truncateToFixedDecimalPlaces(
                    balances.metaMask.balance,
                    isMobile ? 2 : 6
                  )}
                </span>
              )}
              {Number(balances.metaMask.balance) > 0 &&
              flowType === "normal" ? (
                <span
                  className="text-light-high ml-2 font-bold uppercase cursor-pointer"
                  onClick={() =>
                    maxHandler(balances.metaMask.balance.toString())
                  }
                >
                  Max
                </span>
              ) : null}
            </p>
          </div>
          <div>
            <InputText
              type="number"
              placeholder="0.00"
              value={inputAmount}
              disable={false}
              required={true}
              name="stakeInput"
              onChange={inputHandler}
              className={`bg-transparent border-0
             text-light-high leading-normal 
             box-shadow-none font-normal 
             text-3xl m-0 focus:border-0 
             focus:box-shadow-none text-right md:text-lg 
             p-0 mb-2 placeholder:text-light-mid 
             placeholder:leading-normal placeholder:font-normal outline-none max-w-[160px] md:max-w-[100px]`}
            />
            <p className="text-light-low font-normal leading-normal text-right text-sm">
              $
              {formatNumber(
                Number(selectedToken.amount) * tokenPrices.pstakePrice,
                3,
                2
              )}
            </p>
          </div>
        </div>
        {errorCheck !== "" ? (
          <p className="text-red font-normal leading-normal text-xsm pt-2">
            {errorCheck}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Token;
