import React, { useEffect } from "react";
import styles from "./styles.module.css";
import WalletConnection from "./walletConnections";
import Token from "./token";
import Submit from "./submit";
import { useAppStore } from "../../../store/store";
import KeplrTransferModal from "../keplrTransferModal";
import BridgeFee from "./bridgeFee";
import { truncateToFixedDecimalPlaces } from "../../../helpers/utils";
import EthereumTransferModal from "./ethereumTransferModal";

const BridgeContainer = () => {
  const metaMaskInfo = useAppStore((state) => state.metaMaskInfo);
  const flowType = useAppStore((state) => state.flowType);
  const balances = useAppStore((state) => state.balances);
  const keplrInfo = useAppStore((state) => state.keplrInfo);
  const fetchKeplrBalances = useAppStore((state) => state.fetchKeplrBalances);
  const fetchAllowances = useAppStore((state) => state.fetchAllowances);
  const setGravityTxnModal = useAppStore((state) => state.setGravityTxnModal);
  const fetchMetaMaskBalances = useAppStore(
    (state) => state.fetchMetaMaskBalances
  );

  const setCosmosToEthBridgeFee = useAppStore(
    (state) => state.setCosmosToEthBridgeFee
  );

  const gravityHandler = () => {
    setGravityTxnModal(true);
  };

  useEffect(() => {
    if (metaMaskInfo.walletConnected) {
      fetchMetaMaskBalances(metaMaskInfo.instances!, metaMaskInfo.address);
      fetchAllowances(metaMaskInfo.instances!, metaMaskInfo.address);
      setCosmosToEthBridgeFee();
    }
  }, [metaMaskInfo.address]);

  useEffect(() => {
    if (keplrInfo.walletConnected) {
      fetchKeplrBalances(
        keplrInfo.persistenceAddress,
        keplrInfo.gravityAddress
      );
    }
  }, [keplrInfo.persistenceAddress]);

  return (
    <div
      className={`${styles.tabsContainer} max-w-[616px] md:max-w-[500px] m-auto px-10 pb-10 lg:px-3`}
    >
      <div className="p-6 bg-[#18181899] items-center mt-4 rounded-md">
        <p className="text-light-emphasis font-medium mb-2 text-xl text-center">
          Transfer Assets
        </p>
        <WalletConnection />
        <div className="mt-6">
          <Token />
        </div>
        {flowType === "reverse" &&
        metaMaskInfo.walletConnected &&
        keplrInfo.walletConnected ? (
          <div className="mt-4">
            <p className={"text-light-emphasis font-medium mb-2 ml-2"}>
              Bridge Fee
            </p>
            <BridgeFee />
          </div>
        ) : (
          ""
        )}
        {!balances.keplr.gravityBalance.toDec().isZero() ? (
          <p
            className={
              "text-light-emphasis font-normal text-sm mb-2 underline cursor-pointer mt-4 inline-block"
            }
            onClick={gravityHandler}
          >
            Tokens on Gravity Bridge:{" "}
            {truncateToFixedDecimalPlaces(
              balances.keplr.gravityBalance.toString(),
              6
            )}{" "}
            PSTAKE
          </p>
        ) : null}
        <Submit />
      </div>
      <KeplrTransferModal />
      <EthereumTransferModal />
    </div>
  );
};

export default BridgeContainer;
