import React from "react";
import NavigationBar from "../organisms/navigationBar";
import Footer from "../organisms/footer";
import IbcTransferModal from "../organisms/ibcTransferModal";
import TermsModal from "../organisms/termsModal";

export const Template = ({
  children,
  className,
  title,
}: {
  children: React.ReactNode;
  className: string;
  title: string;
}) => {
  return (
    <div className="bg-body-bg">
      <div className="flex md:block flex-col h-screen overflow-auto">
          <NavigationBar />
          <div className={`flex-1 px-8 lg:px-4 bg-no-repeat ` + className}>
          {children}
          </div>
        <Footer />
      </div>
      <TermsModal/>
      <IbcTransferModal />
    </div>
  );
};
