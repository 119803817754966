import React, { useEffect, useState } from "react";
import Button from "../../atoms/button";
import { useAppStore } from "../../../store/store";
import {
  executeCosmosToEthTransaction,
  executeEthApproveTransaction,
  executeEthToCosmosTransaction,
} from "../../../helpers/transaction";
import { utils } from "ethers";
import { Spinner } from "../../atoms/spinner";
import { Dec } from "@keplr-wallet/unit";
import {removeCommas} from "../../../helpers/utils";

const Submit = () => {
  const inputAmount = useAppStore((state) => state.selectedToken.amount);
  const metaMaskInfo = useAppStore((state) => state.metaMaskInfo);
  const checkAllowance = useAppStore((state) => state.checkAllowance);
  const keplrInfo = useAppStore((state) => state.keplrInfo);
  const flowType = useAppStore((state) => state.flowType);
  const transactionInfo = useAppStore((state) => state.transactionInfo);
  const balances = useAppStore((state) => state.balances);
  const amountCheck = useAppStore((state) => state.selectedToken.amountCheck);
  const [balance, setBalance] = useState<string>("0");

  const metaMaskTxnHandler = async () => {
    await executeEthToCosmosTransaction(
      metaMaskInfo.instances?.gravityInstance!,
      {
        persistenceAddress: keplrInfo.persistenceAddress,
        amount: utils.parseEther(inputAmount.toString()),
      }
    );
  };

  useEffect(() => {
    if (metaMaskInfo.walletConnected && flowType === "normal") {
      setBalance(removeCommas(balances.metaMask.balance));
    } else if (keplrInfo.walletConnected && flowType === "reverse") {
      setBalance(removeCommas(balances.keplr.pBalance.toString()));
    }
  }, [
    metaMaskInfo.walletConnected,
    keplrInfo.walletConnected,
    balances,
    flowType,
  ]);

  const keplrTxnHandler = async () => {
    await executeCosmosToEthTransaction(inputAmount);
  };

  const approveHandler = async () => {
    await executeEthApproveTransaction(metaMaskInfo.instances?.pStakeInstance!);
  };

  let enable: boolean;
  if (flowType === "reverse") {
    enable = !amountCheck && inputAmount.gt(new Dec("0"));
  } else {
    enable = inputAmount.gt(new Dec("0")) && inputAmount.lte(new Dec(balance));
  }

  return (
    <div className="mt-6">
      {metaMaskInfo.walletConnected && keplrInfo.persistenceAddress !== "" ? (
        flowType === "normal" ? (
          inputAmount.toString() &&
          utils.parseEther(inputAmount.toString()).gt(checkAllowance) ? (
            <Button
              className={`button w-full md:py-2 md:text-sm flex items-center justify-center`}
              type="primary"
              size="large"
              disabled={
                transactionInfo.name === "approve" && transactionInfo.inProgress
              }
              content={
                transactionInfo.name === "approve" &&
                transactionInfo.inProgress ? (
                  <Spinner size={"small"} />
                ) : (
                  "Approve"
                )
              }
              onClick={approveHandler}
            />
          ) : (
            <Button
              className={`button w-full md:py-2 md:text-sm flex items-center justify-center`}
              type="primary"
              size="large"
              disabled={
                !enable ||
                ((transactionInfo.name === "cosmosTransfer" ||
                  transactionInfo.name === "ethTransfer") &&
                  transactionInfo.inProgress)
              }
              content={
                (transactionInfo.name === "cosmosTransfer" ||
                  transactionInfo.name === "ethTransfer") &&
                transactionInfo.inProgress ? (
                  <Spinner size={"small"} />
                ) : (
                  "Transfer"
                )
              }
              onClick={metaMaskTxnHandler}
            />
          )
        ) : (
          <Button
            className={`button w-full md:py-2 md:text-sm flex items-center justify-center`}
            type="primary"
            size="large"
            disabled={
              !enable ||
              ((transactionInfo.name === "cosmosTransfer" ||
                transactionInfo.name === "ethTransfer") &&
                transactionInfo.inProgress)
            }
            content={
              (transactionInfo.name === "cosmosTransfer" ||
                transactionInfo.name === "ethTransfer") &&
              transactionInfo.inProgress ? (
                <Spinner size={"small"} />
              ) : (
                "Transfer"
              )
            }
            onClick={keplrTxnHandler}
          />
        )
      ) : (
        <Button
          className="button w-full md:py-2 md:text-sm"
          type="primary"
          size="large"
          disabled={true}
          content="Connect wallets"
        />
      )}
    </div>
  );
};

export default Submit;
