import React from "react";
import { useAppStore } from "../../../store/store";
import Modal from "../../molecules/modal";
import { Icon } from "../../atoms/icon";

const KeplrTransferModal = () => {
  const setCosmosToEthTxnModal = useAppStore(
    (state) => state.setCosmosToEthTxnModal
  );
  const inputAmount = useAppStore((state) => state.selectedToken.amount);
  const modal = useAppStore((state) => state.cosmosToEthTxnInfo.modal);
  const stepNumber = useAppStore(
    (state) => state.cosmosToEthTxnInfo.stepNumber
  );

  const gravityTxnFee = useAppStore((state) => state.gravityTxnFee);

  const handleClose = () => {
    setCosmosToEthTxnModal(false);
  };

  return (
    <Modal
      show={modal}
      onClose={handleClose}
      header=""
      className="transfer-modal"
      staticBackDrop={true}
      closeButton={true}
    >
      <p
        className="text-light-high text-center font-semibold text-lg leading normal px-8 pt-8 md:px-6 md:pt-6
       md:text-base"
      >
        Transferring to Ethereum
      </p>
      <div className={`px-8 pt-4 pb-8 md:px-7 md:pt-6 m-0`}>
        <p className="mb-4">
          You will be asked to approve the following transactions.
        </p>
        <p className="text-center text-sm">#Txn : 1</p>
        <div className="bg-black-700 rounded-md flex items-center justify-between p-4 mb-3">
          <div className="flex items-center justify-between">
            <img
              src={"/images/persistence_icon.svg"}
              alt="plogo"
              className="mr-2"
            />
            <p className="text">Persistence</p>
          </div>
          <div
            className={`${
              stepNumber === 1
                ? "border-spinner"
                : "border-2 border-solid border-[#272727]"
            } flex justify-center items-center w-[38px] h-[38px] bg-[#4A4A4A] 
           relative light icon-toast inline-block align-text-bottom rounded-full `}
          >
            <div className="arrow">
              <div
                className={`cursor-pointer icon-box rounded-full flex justify-center items-center
           md:w-[28px] md:h-[28px]`}
              >
                {stepNumber === 2 || stepNumber === -1 ? (
                  <Icon
                    iconName="success-full"
                    viewClass="!w-[1.5rem] !h-[1.5rem] md:!w-[1.3rem] md:!h-[1.3rem]"
                  />
                ) : (
                  <Icon
                    iconName="arrow-right"
                    viewClass=" !w-[18px] md:!w-[10px]"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <img
              src={"/images/gravity_bridge.svg"}
              alt="gravityLogo"
              className="mr-2"
            />
            <p className="text">Gravity Bridge</p>
          </div>
        </div>
        <p className="text-center text-sm">#Txn : 2</p>
        <div className="bg-black-700 rounded-md flex items-center justify-between p-4 mb-3">
          <div className="flex items-center justify-between">
            <img
              src={"/images/gravity_bridge.svg"}
              alt="gravityLogo"
              className="mr-2"
            />
            <p className="text">Gravity Bridge</p>
          </div>
          <div
            className={`${
              stepNumber === 2
                ? "border-spinner"
                : "border-2 border-solid border-[#272727]"
            } flex justify-center items-center w-[38px] h-[38px] bg-[#4A4A4A] 
           relative light icon-toast inline-block align-text-bottom rounded-full `}
          >
            <div className="arrow">
              <div
                className={`cursor-pointer icon-box rounded-full flex justify-center items-center
           md:w-[28px] md:h-[28px]`}
              >
                {stepNumber === -1 ? (
                  <Icon
                    iconName="success-full"
                    viewClass="!w-[1.5rem] !h-[1.5rem] md:!w-[1.3rem] md:!h-[1.3rem]"
                  />
                ) : (
                  <Icon
                    iconName="arrow-right"
                    viewClass=" !w-[18px] md:!w-[10px]"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <img
              src={"/images/eth_logo.svg"}
              alt="gravityLogo"
              className="mr-2"
            />
            <p className="text">Ethereum</p>
          </div>
        </div>

        <div className="bg-black-700 rounded-md p-4 mb-3">
          <div className="flex items-center justify-between">
            <p className="text-light-high text-center text-base leading-normal">
              Amount to transfer
            </p>
            <p className="text-light-mid text-center text-base leading-normal">
              {inputAmount.toString()} PSTAKE
            </p>
          </div>
          <div className="flex items-center justify-between mt-1">
            <p className="text-light-high text-center text-base leading-normal">
              Chain Fee
            </p>
            <p className="text-light-mid text-center text-base leading-normal">
              {gravityTxnFee.chainFee.toString()} PSTAKE
            </p>
          </div>
          <div className="flex items-center justify-between mt-1">
            <p className="text-light-high text-center text-base leading-normal">
              Bridge fee
            </p>
            <p className="text-light-mid text-center text-base leading-normal">
              {gravityTxnFee.selectedBridgeFee.fee.toString()} PSTAKE
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default KeplrTransferModal;
